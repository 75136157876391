'use client'
import categoriesData from '../auctionNavData/categoriesData'
import { CategoriesContainer, ScrollItemList, Item, ItemLink } from '../ui'

const CategoriesLayout = () => {
  return (
    <CategoriesContainer>
      <ScrollItemList>
        {categoriesData.map((item) => (
          <Item key={item.name}>
            <ItemLink href={item.href}>{item.name}</ItemLink>
          </Item>
        ))}
      </ScrollItemList>
    </CategoriesContainer>
  )
}

export default CategoriesLayout
