'use client'
import { useEffect, useState, useRef, Fragment } from 'react'

import { faChevronDown } from '@fortawesome/pro-regular-svg-icons'

import { useAnalytics } from '@helpers/telemetry/SegmentAnalytics'

import auctionNavData from './auctionNavData'
import SubMenu, { submenus, type Submenu } from './auctionNavSubMenu/SubMenu'
import {
  Nav,
  AuctionNavDivider,
  Navbar,
  NavbarLi,
  NavbarLink,
  NavbarUl,
  NavSubmenuIcon,
} from './ui'

export default function HeaderAuctionNavigation() {
  const navbarRef = useRef<HTMLDivElement>(null)
  const [activeSubmenu, setActiveSubmenu] = useState<Submenu | null>(null)

  const rootFontSize =
    typeof window !== 'undefined'
      ? parseFloat(getComputedStyle(document.documentElement).fontSize)
      : 16

  useEffect(() => {
    if (navbarRef.current) {
      const navbar = navbarRef.current
      const button = activeSubmenu
        ? document.querySelector<HTMLDivElement>(`button.${activeSubmenu}`)
        : null
      const submenu = button
        ? (button.nextElementSibling as HTMLDivElement)
        : null

      const setLeft = () => {
        if (button && submenu) {
          const viewportWidth = window.innerWidth
          const buttonRect = button.getBoundingClientRect()
          const navbarRect = navbar.getBoundingClientRect()
          const submenuRect = submenu.getBoundingClientRect()
          const navbarWidth = navbarRect.width
          const submenuWidth = submenuRect.width

          const padding =
            parseFloat(
              getComputedStyle(navbar).getPropertyValue('--submenuPadding')
            ) * rootFontSize
          const maxWidth = 1200 + padding * 2

          if (submenuWidth === viewportWidth) {
            return
          }

          if (submenuWidth > maxWidth) {
            submenu.style.left = '1rem'
            submenu.style.right = '1rem'
            return
          }

          const buttonLeft = buttonRect.left - navbarRect.left - padding
          const relativeRight = buttonLeft + submenuRect.width

          if (relativeRight > navbarWidth) {
            submenu.style.left = 'auto'
            submenu.style.right = viewportWidth >= maxWidth ? '1rem' : '0'
            return
          }

          if (buttonLeft < 0) {
            submenu.style.left = `0`
            submenu.style.right = 'auto'
            return
          }

          submenu.style.left = `${buttonLeft}px`
          submenu.style.right = 'auto'
        }
      }

      setLeft()

      navbar.addEventListener('resize', setLeft)
      navbar.addEventListener('scroll', setLeft)

      return () => {
        navbar.removeEventListener('resize', setLeft)
        navbar.removeEventListener('scroll', setLeft)
      }
    }
  }, [activeSubmenu, rootFontSize])

  const toggleSubmenu = (submenu: Submenu) => {
    setActiveSubmenu(submenu)
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement
      if (submenus.some((submenu) => target.classList.contains(submenu))) {
        setActiveSubmenu(null)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const { trackButtonClicked } = useAnalytics()

  const handleTrackNavigation = (section: string) => {
    return (event: React.MouseEvent) => {
      const target = event.target as HTMLElement
      const anchor = target.closest('a')

      if (anchor) {
        const href = anchor.getAttribute('href')
        let anchorText = anchor.innerText.trim()

        if (!anchorText) {
          const img = anchor.querySelector('img')
          if (img) {
            anchorText = img.getAttribute('alt') || ''
          }
        }

        // Segment analytics event and metadata transcribed from old wordpress code
        // TODO: Update entity_type to based on the role of the user once we
        // implement user authentication.
        trackButtonClicked(
          'homepage', // screen_name
          'header_click', // button_name
          'home_portal', // source
          'buyer', // entity_type
          {
            header_section: section,
            header_subsection: anchorText
              .replace(/[^\w\s]/gi, ' ')
              .toLowerCase()
              .replace(/\s+/g, '_'),
            url: document.URL,
            referrer: document.referrer,
            cta_url: href,
          }
        )
      }
    }
  }

  return (
    <Nav>
      <Navbar ref={navbarRef}>
        <NavbarLink
          href="/all-auctions"
          onClick={(e) => {
            // If we're already on /all-auctions, force a refresh
            if (window.location.pathname === '/all-auctions') {
              e.preventDefault()
              window.location.href = '/all-auctions'
            }
            handleTrackNavigation('all_auctions')(e)
          }}
        >
          All Auctions
        </NavbarLink>

        {auctionNavData.map(({ key, items }, index) => {
          return (
            <Fragment key={key}>
              <AuctionNavDivider $useFullHeight={index === 0} />
              <NavbarUl onClick={handleTrackNavigation(key)}>
                {items.map(({ name, href }) => {
                  const isLink = !!href
                  return (
                    <NavbarLi
                      key={name}
                      onMouseEnter={
                        isLink ? undefined : () => toggleSubmenu(key)
                      }
                    >
                      <NavbarLink
                        as={isLink ? undefined : 'button'}
                        className={isLink ? undefined : key}
                        href={isLink ? href : undefined}
                        onClick={isLink ? undefined : () => toggleSubmenu(key)}
                      >
                        {name}
                        {!isLink && <NavSubmenuIcon icon={faChevronDown} />}
                      </NavbarLink>
                      {!isLink && <SubMenu submenu={key} />}
                    </NavbarLi>
                  )
                })}
              </NavbarUl>
            </Fragment>
          )
        })}
      </Navbar>
    </Nav>
  )
}
