export async function fetchData<T>(url: string): Promise<T> {
  return await fetch(url, {
    next: {
      revalidate: 4,
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }
      return response.json()
    })
    .then((data) => {
      return data as T
    })
    .catch((error: unknown) => {
      console.error('Error fetching data:', error)
      throw error
    })
}

export const AI_SEARCH_ROOT_URL = {
  default: 'https://ai-search-dot-threemp-dw-dev.uw.r.appspot.com',
  production: 'https://ai-search.bstock.com',
}

export const AI_SEARCH_API_ENDPOINTS = {
  amazonBlue:
    '/live-auctions?query=%20&offset=0&limit=50&filter=siteAbb%3AANY%28%22amz%22%29%20AND%20sellerRegion%3AANY%28%22Hebron%22%29',
  amazonUS:
    '/live-auctions?query=%20&offset=0&limit=50&filter=siteAbb%3AANY%28%22amz%22%29',
  fewBidsEndingSoon:
    '/live-auctions?query=%20&offset=0&limit=50&filter=low_bids%20%3D%20"true"&order_by=endTime',
  target:
    '/live-auctions?query=%20&offset=0&limit=50&filter=siteAbb%3AANY%28%22tgt%22%29',
  walmart:
    '/live-auctions?query=%20&offset=0&limit=50&filter=siteAbb%3AANY%28%22wal%22%29',
}

export const LISTING_SEARCH_API_ENDPOINTS = {
  amazonBlue:
    '/all-listings/listings?offset=0&limit=50&storeFrontId=amz&city=Hebron',
  amazonUS: '/all-listings/listings?offset=0&limit=50&storeFrontId=amz',
  fewBidsEndingSoon:
    '/all-listings/listings?fewBids=true&endingSoon=true&offset=0&limit=50',
  target: '/all-listings/listings?offset=0&limit=50&storeFrontId=tgt',
  walmart: '/all-listings/listings?offset=0&limit=50&storeFrontId=wal',
}
