'use client'

import { preventOrphans } from '@helpers/utils'

import {
  Dropdown,
  Nav,
  DropdownTrigger,
  DropdownItem,
  DropdownLinkContainer,
  DDItemName,
  DDItemDescription,
} from './ui'
import navData from '../navData.json'

const dropdownItems = [
  {
    text: 'For Buyers',
    fillWidth: true,
    columns: 4,
    links: navData.forBuyer,
  },
  {
    text: 'For Sellers',
    fillWidth: true,
    columns: 4,
    links: navData.forSeller,
  },
  {
    text: 'Support',
    columns: 2,
    links: navData.support,
  },
]

export default function DropdownNavigation() {
  return (
    <Nav>
      {dropdownItems.map((item) => (
        <DropdownLinkContainer key={item.text}>
          <DropdownTrigger>{item.text}</DropdownTrigger>
          <Dropdown $columns={item.columns} $fullWidth={item.fillWidth}>
            {item.links.map((link) => (
              <DropdownItem
                key={link.href}
                href={link.href}
                data-gtm-element-type="header subitem"
                data-gtm-element-text={link.name}
              >
                <DDItemName>{link.name}</DDItemName>
                <DDItemDescription>
                  {preventOrphans(link.description)}
                </DDItemDescription>
              </DropdownItem>
            ))}
          </Dropdown>
        </DropdownLinkContainer>
      ))}
    </Nav>
  )
}
