'use client'
import conditionsData from '../auctionNavData/conditionsData'
import { ConditionsContainer, PaddedItemList, Item, ItemLink } from '../ui'

const ConditionsLayout = () => {
  return (
    <ConditionsContainer>
      <PaddedItemList>
        {conditionsData.map((item) => (
          <Item key={item.name}>
            <ItemLink href={item.href}>{item.name}</ItemLink>
          </Item>
        ))}
      </PaddedItemList>
    </ConditionsContainer>
  )
}

export default ConditionsLayout
